<template>
  <div>
    授权
  </div>
</template>

<script>
export default {
  name: "auto",
  created() {
    const url = window.location.href;
    const cs = url.split('?')[1];     //获取?之后的参数字符串
    const code = cs.split('=')[1];     //获取=之后的参数字符串

  }
}
</script>

<style scoped>

</style>